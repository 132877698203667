<template>
  <b-modal size="xl" ref="generateProgressModal" :title="getLocalizedText(labels.title)" hide-footer lazy>
    <div class="generate-progress">

      <Messages :messages="errors" type="error"></Messages>
      <Messages :messages="successes" type="success"></Messages>

      <div v-if="!processConnected && errors.length == 0 && report == null">
        {{ getLocalizedText(labels.waitUpdate) }}
      </div>
      
      <div v-if="message != ''" class="message">{{message}}</div>
      <div v-if="percent != undefined" class="progress">
        <div :style="'width: '+percent+'%;'" class="progress-bar"></div>
        <div class="progress-text">{{ percent + '%' }}</div>
      </div>

      <button v-if="processConnected" class="btn btn-secondary mt-5 ladda-button cancel-process" data-style="zoom-in" type="button" @click="onCancelProcessing">{{ getLocalizedText(labels.cancelButton) }}</button>

      <GenerateResult v-if="report" :report="report"></GenerateResult>
    </div>

  </b-modal>
</template>

<style>

.generate-progress .message {
  border:0px;
}

.generate-progress .progress {
  height: 30px;
  position: relative;
}

.generate-progress .progress .progress-text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 6px;
  }
  
</style>



<script lang="ts">
import Messages from '@root/src/client/components/panels/Messages.vue';
import { Message } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import GenerateResult from './GenerateResult.vue';
import { useGenerateAdmin } from '../../composables/useGenerateAdmin';

export default defineComponent({
    props: {
       
    },
    components: {
      Messages,
      GenerateResult
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { listenGenerateReportSocket, cancelProcess:cancelProcessAdmin } = useGenerateAdmin(props, context);

      const generateProgressModal:Ref<HTMLElement|null> = ref(null);
      const processConnected:Ref<boolean> = ref(false);
      const currentProcessID:Ref<string|undefined> = ref(undefined);

      const report:Ref<any> = ref(null);

      const errors:Ref<Message[]> = ref([]);
      const successes:Ref<Message[]> = ref([]);
      const message:Ref<string> = ref("");
      const percent:Ref<number|undefined> = ref(undefined);

      const show = (processID:string) => {
        report.value = null;
        currentProcessID.value = processID;
        // @ts-ignore
        generateProgressModal.value.show();
        listenGenerate(processID);
      }

      const hide = () => {
        if(processConnected.value) {
          processConnected.value = false;
        }
        // @ts-ignore
        generateProgressModal.value.hide()
      }

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {

      })

      const enableLaddaButton = () => {
        var button:HTMLButtonElement|null = document.querySelector( 'button.ladda-button.cancel-process' );
        laddaSubmit = Ladda.create(button!);
      }

      context.expose({
        show,
        hide
      })

      const labels = {
        "title" : {
          "fr" : "Génération en cours",
          "en" : "Generation in progress"
        },
        "waitUpdate" : {
          "fr" : "En attente d'information...",
          "en" : "Waiting for information..."
        },
        "cancelButton" : {
          "fr" : "Annuler",
          "en" : "Cancel"
        }
      }

      const onApiErrors = (apiErrors:Message[]) => {
        errors.value = apiErrors;
      }

      const onApiSuccesses = (apiSuccesses:Message[]) => {
        successes.value = apiSuccesses
      }

      const onApiUpdate = (data:any) => {
        if(!processConnected.value) {
          processConnected.value = true;
        }
        if(data && data.message) {
          message.value = data.message;
        }
        if(data && data.percentage != undefined) {
          percent.value = data.percentage;
        }
        else {
          percent.value = undefined;
        }
      }

      const onProcessCompleted = (data:any) => {
        processConnected.value = false;
        if(data.generated) {
          report.value = data.report;
        }
        message.value = "";
        percent.value = undefined;
      }

      const listenGenerate = (processID:string) => {

        // We clear the current messages
        errors.value = [];
        successes.value = [];

        listenGenerateReportSocket(processID, {completedHandlers: [onProcessCompleted], successesHandler: onApiSuccesses, errorsHandler: onApiErrors, updatesHandler:onApiUpdate})
      }

      const onCancelProcessing = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        enableLaddaButton();

        laddaSubmit!.start();
        cancelProcessAdmin(currentProcessID.value!).then((result:any) => {
          laddaSubmit!.stop();
          // We reset the current process
          currentProcessID.value = undefined;
        });

      }

      return { 
        generateProgressModal,
        processConnected,
        onCancelProcessing,
        report,
        errors,
        successes,
        labels,
        message,
        percent
      }
    }
})
</script>
<template>
  <div class="row generate-success">
    <div class="col-lg-12">
      <div :class="'panel panel-primary'">
        <div class="panel-heading">
            {{ getLocalizedText(labels.reportTitle) }}
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-lg-8">{{ getLocalizedText(labels.fileTypeName) }}</div>
                <div class="col-lg-4">{{ report.fileType.typeName }}</div>
              </div>
              <div class="row">
                <div class="col-lg-8">{{ getLocalizedText(labels.nbVATNumbers) }}</div>
                <div class="col-lg-4">{{ report.nbVATNumbers }}</div>
              </div>
              <div class="row">
                  <div class="col-lg-8">{{ getLocalizedText(labels.nbEmptyRows) }}</div>
                  <div class="col-lg-4">{{ report.nbEmptyRows }}</div>
              </div>
              <div class="row">
                  <div class="col-lg-8">{{ getLocalizedText(labels.nbBadFormatVATNumbers) }}</div>
                  <div class="col-lg-4">{{ report.nbBadFormatVATNumbers }}</div>
              </div>
              <div class="row">
                  <div class="col-lg-8">{{ getLocalizedText(labels.nbValidVATNumbers) }}</div>
                  <div class="col-lg-4">{{ report.nbValidVATNumbers }}</div>
              </div>
              <div class="row">
                  <div class="col-lg-8">{{ getLocalizedText(labels.nbInvalidVATNumbers) }}</div>
                  <div class="col-lg-4">{{ report.nbInvalidVATNumbers }}</div>
              </div>
              <div class="row">
                  <div class="col-lg-8">{{ getLocalizedText(labels.nbUnavailableValidationVATNumbers) }}</div>
                  <div class="col-lg-4">{{ report.nbUnavailableValidationVATNumbers }}</div>
              </div>
              <div class="row" v-if="report.unavailableValidationCountryCodes && report.unavailableValidationCountryCodes.length > 0">
                  <div class="col-lg-8">{{ getLocalizedText(labels.unavailableValidationCountryCodes) }}</div>
                  <div class="col-lg-4">{{ report.unavailableValidationCountryCodes.join(", ") }}</div>
              </div>
              <div class="row">
                  <div class="col-lg-8">{{ getLocalizedText(labels.nbErrorsValidationVATNumbers) }}</div>
                  <div class="col-lg-4">{{ report.nbErrorsValidationVATNumbers }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-lg-12 p-xs">
            <a v-if="report && report.XLSVATValidation" :href="getDownloadURL(report.XLSVATValidation)" target="_blank" class="btn btn-secondary"><i class="fa fa-file-excel-o"></i> {{ getLocalizedText(labels.downloadButton) }}</a>
          </div>
      </div>
    </div>
  </div>
</template>

<style>
  .generate-success .fa {
    font-size: 1.3em;
  }

  .generate-success .panel-body > .row > .col > .row {
    border-bottom: 1px solid #CCC;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .generate-success .panel-body > .row > .col > .row:first-child {
    margin-top:-10px;
  }

  .generate-success .panel-body > .row > .col > .row:last-child {
    border-bottom: none;
    margin-bottom:-10px;
  }
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useReportAdmin } from '../../composables/useReportAdmin';

export default defineComponent({
    props: {
      report: {
        type: Object,
        required: true
      }
    },
    components: {
      
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { getReportURL } = useReportAdmin(props, context);

      const { report } = toRefs(props.report)

      const labels = {
        "fileTypeName" : {
          "fr" : "Type de fichier",
          "en" : "File type"
        },
        "nbVATNumbers" : {
          "fr" : "Nombre de numéros TVA",
          "en" : "Number of VAT numbers"
        },
        "nbEmptyRows" : {
          "fr" : "Nombre de lignes vides",
          "en" : "Number of empty rows"
        },
        "nbBadFormatVATNumbers" : {
          "fr" : "Nombre de numéros TVA mal formattés",
          "en" : "Number of bad formatted VAT numbers"
        },
        "nbValidVATNumbers" : {
          "fr" : "Nombre de numéros TVA valides",
          "en" : "Number of valid VAT numbers"
        },
        "nbInvalidVATNumbers" : {
          "fr" : "Nombre de numéros TVA invalides",
          "en" : "Number of invalid VAT numbers"
        },
        "nbUnavailableValidationVATNumbers" : {
          "fr" : "Nombre de numéros TVA non validables",
          "en" : "Number of VAT numbers that cannot be validated"
        },
        "unavailableValidationCountryCodes" : {
          "fr" : "Codes pays non validables",
          "en" : "Country codes that cannot be validated"
        },
        "nbErrorsValidationVATNumbers" : {
          "fr" : "Nombre de numéros TVA avec erreurs de validation",
          "en" : "Number of VAT numbers with validation errors"
        },
        "reportTitle" : {
          "fr" : "Rapport",
          "en" : "Report"
        },
        "downloadButton" : {
          "fr" : "Télécharger",
          "en" : "Download"
        }
      }

      const getDownloadURL = (file:any) => {
        if(!file) return null;
        return getReportURL(file._id);
      }

      return { 
        getDownloadURL,
        report,
        labels,
      }
    }
})
</script>
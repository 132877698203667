<template>
  <div class="ibox files">
    <div class="ibox-title">
      <h2>{{ getLocalizedText(labels.reportListTitle) }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <SwitchCheck 
        v-if="hasUserRole(apiRoles.superadmin)"
        v-bind:value.sync="generateForm.isDebug" 
        id="isDebug" 
        :label="'Debug'" 
        :labelAsColumn="true"
      />

      <div class="row">

        <b-pagination
            v-model="currentPage"
            :total-rows="reports.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>

      </div>

      <div class="row">

        <b-table 
            responsive
            outlined striped
            :items="reports"
            :fields="reportsFields"
            :per-page="perPage"
            :current-page="currentPage"
            ref="reportsFiles">
          <template v-slot:cell(options)="row">
            <div v-if="isReportProcessing(row.item)">
              {{ getLocalizedText(labels.reportProcessing) }}
            </div>
            <div v-else>
              <a v-if="row.item.nbUnavailableValidationVATNumbers > 0" href="javascript:void(0)" @click="refreshReport(row.item._id)">{{ getLocalizedText(labels.checkReportUnavailableVatNumbersButton) }}</a>
              <a v-if="row.item.source" :href="getFileURL(row.item.source._id)"><i class="fa fa-file-o"></i></a>
              <a v-if="row.item.XLSVATValidation" :href="getFileURL(row.item.XLSVATValidation._id)"><i class="fa fa-file-excel-o"></i></a>
              <a href="javascript:void(0)" @click="confirmRemoveReport(row.item)"><i class="fa fa-trash-o"></i></a>
            </div>
          </template>
        </b-table>

      </div>

    </div>

    <GenerateProgressModal ref="generateProgressModal" />

    <b-modal ref="removeReportModal" 
        hide-header
        @ok="removeReport">
      {{ 
        confirmRemoveReportTitle
      }}
    </b-modal>

  </div>
</template>

<style scoped>
  .b-table .options a:has(i) {
    font-size: 1.3em;
    margin-right: 10px;
  }
</style>



<script lang="ts">
import Messages from '@root/src/client/components/panels/Messages.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, ComputedRef} from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { formatDay, formatDate, FormatDateType, FromLocalToUTC, formatDateForInput, formatMonth } from '@igotweb-node-api-utils/formatter';
import { languagesTypes } from '@fwk-client/store/types';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import GenerateProgressModal from './GenerateProgressModal.vue';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { authenticationTypes } from '@fwk-client/store/types';

import { useModuleAdmin } from '../../composables/useModuleAdmin';
import { useGenerateAdmin } from '../../composables/useGenerateAdmin';
import { useReportAdmin } from '../../composables/useReportAdmin';

export default defineComponent({
  props: {
      
  },
  components: {
    Messages,
    Select,
    SwitchCheck,
    GenerateProgressModal
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { selectedModule, modules } = useModuleAdmin(props, context);
    const { isListLoading:isGenerateLoading, checkReportUnavailableVatNumbers, generateForm, currentProcesses, updateCurrentProcesses } = useGenerateAdmin(props, context);
    const { reports, updateListReports, removeReport:removeReportAdmin, getReportURL, isListLoading } = useReportAdmin(props, context);
    updateListReports();

    const generateProgressModal:Ref<HTMLElement|null> = ref(null);

    const hasUserRole = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];
     
    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const perPage:number = 10;
    const currentPage:Ref<number> = ref(1);

    const reportsFields = [
      {
        key: "sourceFileName",
        label: ""
      },
      {
        key: "fileType.typeName",
        label: ""
      },
      {
        key: "formattedCreationDateTime",
        label: ""
      },
      {
        key: "nbVATNumbers",
        label: ""
      },
      {
        key: "nbEmptyRows",
        label: ""
      },
      {
        key: "nbBadFormatVATNumbers",
        label: ""
      },
      {
        key: "nbValidVATNumbers",
        label: ""
      },
      {
        key: "nbInvalidVATNumbers",
        label: ""
      },
      {
        key: "nbUnavailableValidationVATNumbers",
        label: ""
      },
      {
        key: "nbErrorsValidationVATNumbers",
        label: ""
      },
      {
        key: "options",
        label: "",
        class: "options"
      }
    ];

    const removeReportModal:Ref<HTMLElement|null> = ref(null);
    const reportsFiles:Ref<HTMLElement|null> = ref(null);

    const labels = {
      "headerSourceFileName" : {
        "en": "Source file",
        "fr": "Fichier source"
      },
      "headerFileType" : {
        "en": "Type",
        "fr": "Type"
      },
      "headerCreationDateTime" : {
        "en": "Creation date",
        "fr": "Date de création"
      },
      "headerNbVATNumbers" : {
        "fr" : "Nombre de numéros TVA",
        "en" : "Number of VAT numbers"
      },
      "headerNbEmptyRows" : {
        "fr" : "Nombre de lignes vides",
        "en" : "Number of empty rows"
      },
      "headerNbBadFormatVATNumbers" : {
        "fr" : "Nombre de numéros TVA mal formattés",
        "en" : "Number of bad formatted VAT numbers"
      },
      "headerNbValidVATNumbers" : {
        "fr" : "Nombre de numéros TVA valides",
        "en" : "Number of valid VAT numbers"
      },
      "headerNbInvalidVATNumbers" : {
        "fr" : "Nombre de numéros TVA invalides",
        "en" : "Number of invalid VAT numbers"
      },
      "headerNbUnavailableValidationVATNumbers" : {
        "fr" : "Nombre de numéros TVA non validés",
        "en" : "Number of unavailable validation VAT numbers"
      },
      "headerNbErrorsValidationVATNumbers" : {
        "fr" : "Nombre d'erreurs de validation",
        "en" : "Number of validation errors"
      },
      "headerOptions" : {
        "en": "Options",
        "fr": "Options"
      },
      "confirmRemoveReport" : {
        "en": "Are you sure you want to remove the report for the file: {0}?",
        "fr": "Êtes-vous sûr de vouloir supprimer le rapport du fichier: {0}?"
      },
      "reportListTitle": {
        "en": "Generated reports",
        "fr": "Rapports générés"
      },
      "checkReportUnavailableVatNumbersButton" : {
        "en": "Check unavailable VAT numbers",
        "fr": "Vérifier les numéros TVA non validés"
      },
      "reportProcessing" : {
        "en": "Processing in progress",
        "fr": "Génération en cours"
      }
    }

    const confirmRemoveReportTitle = computed(() => {
      return app.getLocalizedText(labels.confirmRemoveReport,{
        params : (reportToRemove.value && reportToRemove.value.sourceFileName) ? [reportToRemove.value.sourceFileName] : undefined
      })
    })
    

    const updateTableLabels = () => {
      reportsFields[0].label = app.getLocalizedText(labels.headerSourceFileName) as string;
      reportsFields[1].label = app.getLocalizedText(labels.headerFileType) as string;
      reportsFields[2].label = app.getLocalizedText(labels.headerCreationDateTime) as string;
      reportsFields[3].label = app.getLocalizedText(labels.headerNbVATNumbers) as string;
      reportsFields[4].label = app.getLocalizedText(labels.headerNbEmptyRows) as string;
      reportsFields[5].label = app.getLocalizedText(labels.headerNbBadFormatVATNumbers) as string;
      reportsFields[6].label = app.getLocalizedText(labels.headerNbValidVATNumbers) as string;
      reportsFields[7].label = app.getLocalizedText(labels.headerNbInvalidVATNumbers) as string;
      reportsFields[8].label = app.getLocalizedText(labels.headerNbUnavailableValidationVATNumbers) as string;
      reportsFields[9].label = app.getLocalizedText(labels.headerNbErrorsValidationVATNumbers) as string;
      reportsFields[10].label = app.getLocalizedText(labels.headerOptions) as string;
    }

   

    onMounted(() => {
      updateTableLabels();
    });

    watch(
      currentLanguageCode,
      (val:any, oldVal:any) => {
      updateTableLabels();
      },
      { deep: false }
    )

    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value || isGenerateLoading.value;
    })
    
    const emptyReport = {};
    const reportToRemove:Ref<any> = ref({...emptyReport});

    const getFileURL = (fileId:any) => {
      var url = getReportURL(fileId);
      return url;
    }

    const onRefreshReportCompleted = () => {
      updateListReports();
    }

    const refreshReport = (reportID:string) => {
      checkReportUnavailableVatNumbers(reportID, {completedHandlers:[onRefreshReportCompleted]}).then((result:any) => {
        if(result && result.processID) {
          updateCurrentProcesses();
          // @ts-ignore
          generateProgressModal.value.show(result.processID);
        }
      })
    }

    const isReportProcessing = (report:any) => {
      if(report.nbUnavailableValidationVATNumbers == 0) { return; }
      return currentProcesses.value.find((process:any) => process.data && process.data.vatValidationReportID === report._id);
    }

    const onReportRemoved = () => {
      updateListReports();
    }

    const confirmRemoveReport = (report:any) => {
      reportToRemove.value = report;
      // @ts-ignore
      removeReportModal.value.show()
    }

    const removeReport = () => {

      removeReportAdmin(reportToRemove.value._id).then((result:any) => {
        if(result.removed) {
          // We update the list of files
          onReportRemoved();
        }
        // We reset the report to remove
        reportToRemove.value = {...emptyReport};

      });
    }

    return { 
      listLoading,
      generateProgressModal,
      hasUserRole,
      apiRoles,
      reports,
      reportsFields,
      perPage,
      currentPage,
      reportsFiles,
      getFileURL,
      confirmRemoveReport,
      removeReport,
      refreshReport,
      removeReportModal,
      reportToRemove,
      labels,
      confirmRemoveReportTitle,
      generateForm,
      isReportProcessing
    }
  }
})
</script>
import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useModuleAdmin } from "./useModuleAdmin";
import { types as vatValidationTypes } from '@root/src/client/modules/vat-validation/store';
import { ApiOptions } from "@fwk-client/utils/api";

interface GenerateAdminInput {
}

export function useGenerateAdmin(props:GenerateAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedModule, callModuleAdmin, emitModuleAdmin } = useModuleAdmin(props, {emit})

  const generateForm:any = reactive({
    file: null,
    isDebug: undefined
  });

  const currentProcesses = computed(() => {
    return store.getters['vat-validation/'+vatValidationTypes.getters.GET_GENERATE_REPORT_PROCESSES];
  })

  var isListLoading:Ref<boolean> = ref(false);
  var isProcessLoading:Ref<boolean> = ref(false);

  const getSupportedFileTypeConfigs = async () => {
    var result:any = {
      fileTypeConfigs: []
    }

    try {
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/supported-file-types');
      if(response.fileTypeConfigs) {  
        result = {
          ...response
        }
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const checkFile = async (options?:ApiOptions) => {
    var result:any = {
      validated: false
    }

    var input = {
      isDebug: generateForm.isDebug
    }

    var formData = new FormData();
    if(generateForm.file != null) {
      formData.append("file", generateForm.file!, (generateForm.file! as File).name);  
    }

    try {
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/check-file', input, formData, options);
      if(response.validated) {  
        result = {
          ...response
        }
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  var isUpdateCurrentProcessesOnGoing = false;
  const updateCurrentProcesses = async () => {
    try {
      if(isUpdateCurrentProcessesOnGoing) {
        return;
      }
      isUpdateCurrentProcessesOnGoing = true;
      isProcessLoading.value = true;
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/current-processes');
      isProcessLoading.value = false;
      store.commit('vat-validation/'+vatValidationTypes.mutations.SET_GENERATE_REPORT_PROCESSES, response.processes ? response.processes : []);
      isUpdateCurrentProcessesOnGoing = false;
    }
    catch(error:any) {
      isProcessLoading.value = false
      console.log(error);
    }
  }

  if(isProcessLoading.value == false && currentProcesses.value.length == 0) {
    updateCurrentProcesses();
  }

  const cancelProcess = async (processID:string) => {
    var result:any = {
      cancelled: false
    }

    var input = {
      processID: processID
    }

    try {
      isProcessLoading.value = true;
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/cancel-process', input);
      isProcessLoading.value = false;
      if(response.cancelled) {  
        result.cancelled = true;
        await updateCurrentProcesses();
      }
    }
    catch(error:any) {
      isProcessLoading.value = false
      console.log(error);
    }
    return result;
  }

  const generateReportSocket = async (options?:{completedHandlers?:Function[], successesHandler?:Function, errorsHandler?:Function, updatesHandler?:Function}) => {

    var result:any = {
      processID:null
    }

    var input = {
      isDebug: generateForm.isDebug,
      moduleID: selectedModule.value.module._id,
      // file: generateForm.file
    }

    var completeHandler = (response:any) => {
      // We force the update of processes
      updateCurrentProcesses();
    }

    var computedOptions = {
      ...options,
      completedHandlers: options && options.completedHandlers ? options.completedHandlers.concat([completeHandler]) : [completeHandler],
    }

    try {
      isListLoading.value = true;
      var response = await emitModuleAdmin('/generate-report', computedOptions, input, generateForm.file);
      isListLoading.value = false;
      if(response.processID) {
        result.processID = response.processID;
      }
      return result;
    }
    catch(error:any) {
      isListLoading.value = false;
      console.log(error);
    }
    return result;

  }

  const listenGenerateReportSocket = async (processID:string, options?:{completedHandlers?:Function[], successesHandler?:Function, errorsHandler?:Function, updatesHandler?:Function}) => {
    var result:any = {
      processID:null
    }

    var input = {
      processID:processID,
      moduleID: selectedModule.value.module._id,
      // file: generateForm.file
    }

    var completeHandler = (response:any) => {
      // We force the update of processes
      updateCurrentProcesses();
    }

    var computedOptions = {
      ...options,
      completedHandlers: options && options.completedHandlers ? options.completedHandlers.concat([completeHandler]) : [completeHandler],
    }

    try {
      isListLoading.value = true;
      var response = await emitModuleAdmin('/generate-report', computedOptions, input);
      isListLoading.value = false;
      if(response.processID) {
        result.processID = response.processID;
      }
      return result;
    }
    catch(error:any) {
      isListLoading.value = false;
      console.log(error);
    }
    return result;
  }

  const checkReportUnavailableVatNumbers = async (reportID:string, options?:{completedHandlers?:Function[], successesHandler?:Function, errorsHandler?:Function, updatesHandler?:Function}) => {
    var result:any = {
      processID:null
    }

    var input = {
      isDebug: generateForm.isDebug,
      moduleID: selectedModule.value.module._id,
      vatValidationReportID: reportID
    }

    var completeHandler = (response:any) => {
      console.log("CHECK REPORT UNAVAILABLE VAT NUMBERS - COMPLETE HANDLER");
      // We force the update of processes
      updateCurrentProcesses();
    }

    var computedOptions = {
      ...options,
      completedHandlers: options && options.completedHandlers ? options.completedHandlers.concat([completeHandler]) : [completeHandler],
    }

    try {
      isListLoading.value = true;
      var response = await emitModuleAdmin('/generate-report', computedOptions, input);
      isListLoading.value = false;
      if(response.processID) {
        result.processID = response.processID;
      }
      return result;
    }
    catch(error:any) {
      isListLoading.value = false;
      console.log(error);
    }
    return result;
  }

  return {
    isListLoading,
    isProcessLoading,
    generateForm,
    currentProcesses,
    checkFile,
    generateReportSocket,
    checkReportUnavailableVatNumbers,
    listenGenerateReportSocket,
    updateCurrentProcesses,
    cancelProcess,
    getSupportedFileTypeConfigs
  }
  
}
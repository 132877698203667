import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useModuleAdmin } from "./useModuleAdmin";

interface ReportAdminInput {
}

export function useReportAdmin(props:ReportAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedModule, callModuleAdmin } = useModuleAdmin(props, {emit})

  var isListLoading:Ref<boolean> = ref(false);

  const reports = ref([]);

  const updateListReports = async () => {

    var input:any = {
    }

    try {
      isListLoading.value = true;
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/list-reports', input);
      isListLoading.value = false;
      reports.value = response.reports ? response.reports : [];
    }
    catch(error:any) {
      isListLoading.value = false;
      console.log(error);
    }

  }

  const removeReport = async (reportID:string) => {

    var result:any = {
      removed:false
    }

    var input = {
      "reportID" : reportID
    }

    try {
      isListLoading.value = true;
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/report/remove', input);
      isListLoading.value = false;
      if(response.removed) {
        result.removed = true;
      }
      return result;
    }
    catch(error:any) {
      isListLoading.value = false;
      console.log(error);
    }
    return result;

  }

  const getReportURL = (fileId:any) => {
    var url = '/api/admin/vat-validation/'+selectedModule.value.module._id+'/file/'+fileId+'/download';
    return url;
  }

  watch(
    () => selectedModule.value.module._id,
    (val:any, oldVal:any) => {
      // We update the reports
      updateListReports();
    },
    { deep: false }
  )
    

  return {
    isListLoading,
    updateListReports,
    reports,
    removeReport,
    getReportURL
  }
  
}